

<template>
    <div> </div>      
</template>


<!-- API全局变量 -->
<script>    
  // 本地 接口地址
  const localSrc = 'http://192.168.1.116:9999'; 
  // 线上 接口地址
  const serverSrc = 'http://www.xxxxxx.com:8080/jobs-api';

  export default{
      localSrc,
      serverSrc
  }
</script>

<style>
</style>

